import Header from "./shared/Header";
import Footer from "./shared/Footer";
import Banner from "./shared/Banner";

function RefundPolicy() {
    return (
        <>
            <Header />
            <Banner name='Refund Policy' parentSlug='/' parent='Home' />

            <div className="container py-5">
                <h4 className="h3 fw-bold mb-2">REFUNDS</h4>
                <ul>
                    <li><h4 className="h6 mb-4">Within 7 Days From Delivery</h4></li>
                    <li><h4 className="h6 mb-4">Refunds will be issued within 72 hours upon the delivery of the return.</h4></li>
                    <li><h4 className="h6 mb-4">Products will be fully refunded if returned with the original packaging and correct condition.</h4></li>
                    <li><h4 className="h6 mb-4">A 10% restocking fee will be charged for incomplete returns or returns with missing or damaged packaging.</h4></li>
                    <li><h4 className="h6 mb-4">Please contact us for all returns. All shipping costs to return the product will be paid by the customer.</h4></li>
               </ul>
               <h4 className="h6 mb-4"><strong>NOTE:</strong> Refunds are not applicable past 7 days from delivery.</h4>

               <h4 className="h3 fw-bold mb-2">CANCELLATION</h4>
                <ul>
                    <li><h4 className="h6 mb-4">If the order has not shipped yet, we will cancel the order and issue a full refund right away.</h4></li>
                    <li><h4 className="h6 mb-4">If the order has shipped, please reject the package at the time of delivery. If unable to do so, please notify us that the package has been delivered so that we can help you ship the package back to us. You will be fully refunded within 72 hours upon the delivery of the return.</h4></li>
               </ul>
               <h4 className="h3 fw-bold mb-2">REPLACEMENT AND REPAIRS</h4>
               <h4 className="h6 mb-4"><big className="fw-bold">Within 7 Days From Delivery</big><br/>
               Any product that is defective is eligible for replacement. <br/>
               Please contact us for all returns. Once we approve replacement request, we'll guide you in returning the parcel back to us. All shipping costs to return the product will be paid by the customer.
               </h4>
               <h4 className="h6 mb-4"><big className="fw-bold">Within 90 days From Delivery</big><br/>
               We offer complete 90 days warranty and free repairs for our products.</h4>
               <h4 className="h6 mb-4"><big className="fw-bold">Past 90 days From Delivery</big><br/>
               The customer will be charged for any customized hydro-dipped products that need to be repaired. The price will vary. Once we receive the product, we can assess it for free. Then you will be notified of the cost of the repair. We will proceed with the repair once we get your consent and payment.</h4>

               <h4 className="h6 mb-4"> We also provide repair service for third party custom controllers. please provide detailed description of the issue on our support number.</h4>
            </div>
            <Footer />
        </>
    );
}

export default RefundPolicy;
