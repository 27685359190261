import React, { useState, useEffect, useRef } from 'react';
import {Link, useParams} from 'react-router-dom';
import axios from 'axios';
import appConstant from './constant/constant.json';
import ScrollToTop from './ScrollToTop'

function Header(props) {
    const inputRef = useRef(null);
    const inputRef1 = useRef(null);
    const getProp = useParams();
    const [passwordShown, setPasswordShown] = useState(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [resultMessage, setResultMessage] = useState('');
    const [showResetPasswordBox, setShowResetPasswordBox] = useState(false);
    const [isVerified, setIsVerified] = useState(true);
    const [marquees, setMarquees] = useState([]);
    const [checkRequestMessage, setCheckRequestMessage] = useState('');

    useEffect(() => {
        marquee();
        if (localStorage.getItem('token')) {
            setMyAccount(true);
           // cartTotal();
        }
        if(getProp && getProp.email && getProp.otp){
            checkRequest();
            setShowResetPasswordBox(true);
            setIsLoading(false);
            setLogin(false);
            setSignup(false);
            setForgot(false);
            setReset(true);
        }
    }, []);

    // header scroll effect
    const [headerActiveClass, setHeaderActiveClass] = useState(false);
    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 30) {
                setHeaderActiveClass(true);
            } else {
                setHeaderActiveClass(false);
            }
        });
    }, []);
    // header scroll effect end

    // cart total
    const cartTotal = () => {
        // const params = {
        //     'Content-Type': 'application/json',
        //     'Access-Control-Allow-Origin': '*'
        // }
        // const cartData = new FormData();
        // cartData.append('token', localStorage.getItem('token') ? localStorage.getItem('token') : '' );
        // axios.post(`${appConstant.API_URL}/products/cart_total`, cartData, params)
        //     .then(res => {
        //         if(res?.data?.status === 200){
        //             localStorage.setItem('cartTotal', res?.data?.data?.cart_total);
        //         }else{
        //             localStorage.setItem('cartTotal', 0);
        //         }
        //     })
        //     .catch(error => {
        //     });
        const getCart = localStorage.getItem('cart');
        if(getCart){
            const CartTotal = JSON.parse(getCart);
            return (CartTotal && CartTotal?.length > 0) ? CartTotal?.length : 0;
        }else{
            return 0;
        }
    }
    // cart total end 

    const togglePassword = (e) => {
        setPasswordShown(!passwordShown);
    };
    const toggleConfirmPassword = (e) => {
        setConfirmPasswordShown(!confirmPasswordShown);
    };

    const [login, setLogin] = useState(true);
    const [signup, setSignup] = useState(false);
    const [forgot, setForgot] = useState(false);
    const [reset, setReset] = useState(false);
    const [myAccount, setMyAccount] = useState(false);
    const showLogin = () => {
        setLogin(true);
        setSignup(false);
        setForgot(false);
        setReset(false);
        setResultMessage('');
    }
    const showSignup = () => {
        setLogin(false);
        setSignup(true);
        setForgot(false);
        setReset(false);
        setResultMessage('');
    }
    const showForgot = () => {
        setLogin(false);
        setSignup(false);
        setForgot(true);
        setReset(false);
        setResultMessage('');
    }
    // login
    const [loginInputValues, setLoginInputValues] = useState({
        email : "",
        password : "",
    });
    const [checked, setChecked] = useState(false);
    const handleLoginChange = (e) => {
        const {name, value} = e.target;
        setLoginInputValues({...loginInputValues, [name]: value});
    }
    const [loginValidation, setLoginValidation] = useState({
        email : "",
        password : ""
    });
    const checkLoginValidation = () => {
        let errors = JSON.parse(JSON.stringify(loginValidation));

        // email validation
        const emailCond = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!loginInputValues.email) {
          errors.email = "Email is required";
        } else if (!loginInputValues.email.match(emailCond)) {
          errors.email = "Please enter valid email address";
        } else {
          errors.email = "";
        }

        //password validation
        const password = loginInputValues.password;
        // const passReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6})/;
        if (!password) {
          errors.password = "Password is required";
        }
        // else if (!password.match(passReg)) {
        //   errors.password = "Password must be at least 6 characters and must include at least one upper case letter, one lower case letter, one special character and one numeric digit.";
        // }
        else {
          errors.password = "";
        }
        setLoginValidation(errors);
        if(errors.email === '' && errors.password === ''){
          return true;
        }else{
          return false;
        }
    };
    const logIn = (e) => {
        e.preventDefault();
        const isSuccess = checkLoginValidation();
        if(isSuccess) {
            setIsLoading(true);
            const loginData = new FormData();
            loginData.append('email', loginInputValues.email );
            loginData.append('password', loginInputValues.password );
            const params = {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*"
            };
            axios.post(`${appConstant.API_URL}/user/login`, loginData, params)
                .then(res => {
                    setIsLoading(false);
                    if (res.data.status === 200) {
                        setMyAccount(true);
                        localStorage.setItem("token", res?.data?.data?.token);
                        localStorage.setItem("full_name", res?.data?.data?.full_name);
                        localStorage.setItem('cartTotal', 0);
                        window.location = window.location.href;
                        setResultMessage(res?.data?.message);
                    }else {
                        setResultMessage(res?.data?.message);
                    }
                })
                .catch(error => {
                    setIsLoading(false);
                });
        }
    }
    // sign up
    const [signupInputValues, setSignupInputValues] = useState({
        fname : "",
        lname : "",
        number : "",
        email : "",
        password : "",
        cpassword : "",
        // address : "",
        // state : "",
        // city : "",
        // country : "",
        // zip : "",
    });
    const handleSignupChange = (e) => {
        const {name, value} = e.target;
        setSignupInputValues({...signupInputValues, [name]: value});
    }
    const [signupValidation, setSignupValidation] = useState({
        fname : "",
        lname : "",
        number : "",
        email : "",
        password : "",
        cpassword : "",
        // address : "",
        // state : "",
        // city : "",
        // country : "",
        // zip : ""
    });
    const checkSignupValidation = () => {
        let errors = JSON.parse(JSON.stringify(signupValidation));

        //first validation
        const alphabetCond = /^[a-zA-Z ]*$/;
        if (!signupInputValues.fname) {
        errors.fname = "First name is required";
        } else if (!signupInputValues.fname.match(alphabetCond)) {
        errors.fname = "Please enter valid first name";
        } else {
        errors.fname = "";
        }
        //last validation
        if (!signupInputValues.lname) {
        errors.lname = "Last name is required";
        } else if (!signupInputValues.lname.match(alphabetCond)) {
        errors.lname = "Please enter valid last name";
        } else {
        errors.lname = "";
        }
        //number validation
        const numberCond = /^[0-9\b]+$/;
        if (!signupInputValues.number) {
        errors.number = "Number is required";
        } else if (!signupInputValues.number.match(numberCond)) {
        errors.number = "Please enter valid number";
        } else {
        errors.number = "";
        }

        // email validation
        const emailCond = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!signupInputValues.email) {
          errors.email = "Email is required";
        } else if (!signupInputValues.email.match(emailCond)) {
          errors.email = "Please enter valid email address";
        } else {
          errors.email = "";
        }

        //password validation
        const password = signupInputValues.password;
        // const passReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6})/;
        if (!password) {
          errors.password = "Password is required";
        }
        // else if (!password.match(passReg)) {
        //   errors.password = "Password must be at least 6 characters and must include at least one upper case letter, one lower case letter, one special character and one numeric digit.";
        // }
        else {
          errors.password = "";
        }
        //confirm password validation
        const cpassword = signupInputValues.cpassword;
        if (!cpassword) {
        errors.cpassword = 'Repeat Password';
        } else if (cpassword !== password) {
        errors.cpassword = 'Both password should match';
        } else {
        errors.cpassword = '';
        }
        //address validation
        // if (!signupInputValues.address) {
        // errors.address = "Address is required";
        // }else {
        // errors.address = "";
        // }
        //country validation
        // if (!signupInputValues.country) {
        //   errors.country = "Country is required";
        //   }else {
        //   errors.country = "";
        // }
        //state validation
        // if (!signupInputValues.state) {
        //   errors.state = "State is required";
        //   }else {
        //   errors.state = "";
        // }
        //city validation
        // if (!signupInputValues.city) {
        //   errors.city = "City is required";
        //   }else {
        //   errors.city = "";
        // }
        //zip validation
        // if (!signupInputValues.zip) {
        //   errors.zip = "Zip is required";
        //   }else {
        //   errors.zip = "";
        // }
        setSignupValidation(errors);
        if(errors.fname === '' && errors.lname === '' && errors.number === '' && errors.email === '' && errors.password === '' && errors.cpassword === '' 
        // && errors.address === '' && errors.country === '' && errors.state === ''&& errors.city === '' && errors.zip === ''
        ){
          return true;
        }else{
          return false;
        }
    };
    const signUp = (e) => {
        e.preventDefault();
        const isSuccess = checkSignupValidation();
        if(isSuccess) {
            setIsLoading(true);
            const signupData = new FormData();
            signupData.append('first_name', signupInputValues.fname);
            signupData.append('last_name', signupInputValues.lname);
            signupData.append('phone_number', signupInputValues.number);
            signupData.append('email', signupInputValues.email);
            signupData.append('password', signupInputValues.password);
            // signupData.append('address', signupInputValues.address);
            // signupData.append('country', signupInputValues.country);
            // signupData.append('state', signupInputValues.state);
            // signupData.append('city', signupInputValues.city);
            // signupData.append('zip_code', signupInputValues.zip);
            const params = {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            };
            axios.post(`${appConstant.API_URL}/user/signup`, signupData, params)
                .then(res => {
                    setIsLoading(false);
                    if (res?.data?.status === 200) {
                        setMyAccount(true);
                        localStorage.setItem("token", res?.data?.data?.token);
                        localStorage.setItem("full_name", res?.data?.data?.full_name);
                        localStorage.setItem('cartTotal', 0);
                        window.location = window.location.href;
                        setResultMessage(res?.data?.message);
                    }else {
                        setResultMessage(res?.data?.message);
                    }
                })
                .catch(error => {
                     setIsLoading(false);
                });
        }
    }

    const getInitials = (name) => {
        const nameWords = name.split(' ');
        const initials = nameWords.map((word) => word.charAt(0).toUpperCase());
        return initials.join('');
      };
    // forgot password
    const [forgotInputValues, setForgotInputValues] = useState({
        email : ""
    });
    const handleForgotChange = (e) => {
        const {name, value} = e.target;
        setForgotInputValues({...forgotInputValues, [name]: value});
    }
    const [forgotValidation, setforgotValidation] = useState({
        email : ""
    });
    const checkForgotValidation = () => {
        let errors = JSON.parse(JSON.stringify(forgotValidation));

        // email validation
        const emailCond = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!forgotInputValues.email) {
          errors.email = "Email is required";
        } else if (!forgotInputValues.email.match(emailCond)) {
          errors.email = "Please enter valid email address";
        } else {
          errors.email = "";
        }
        setforgotValidation(errors);
        if(errors.email === ''){
          return true;
        }else{
          return false;
        }
    };
    const forgotPassword = (e) => {
        e.preventDefault();
        const isSuccess = checkForgotValidation();
        if(isSuccess) {
            setIsLoading(true);
            setLogin(false);
            setSignup(false);
            const forgotData = new FormData();
            forgotData.append('email', forgotInputValues.email);
            const params = {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            };
            axios.post(`${appConstant.API_URL}/user/forget_password`, forgotData, params)
            .then(res => {
              setIsLoading(false);
              if (res?.data?.status === 200) {
                // window.location = window.location.href;
                setResultMessage(res?.data?.message);
               
              }else {
                setResultMessage(res?.data?.message);
              }
            })
            .catch(error => {
              setIsLoading(false);
            });
        }
    }
    const checkRequest = () => {
        const checkRequestData = new FormData();
        checkRequestData.append('email', getProp.email);
        checkRequestData.append('otp', getProp.otp);
        const params = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        };
        axios.post(`${appConstant.API_URL}/user/check_otp`, checkRequestData, params)
            .then(res => {
                setIsLoading(false);
                if (res?.data?.status === 200) {
                    setIsVerified(true);
                }else {
                    setIsVerified(false);
                    setCheckRequestMessage(res?.data?.message);
                }
            })
            .catch(error => {
                setIsVerified(false);
            });
    }
    const ResetPassword = () => {
        const checkRequestData = new FormData();
        checkRequestData.append('email', getProp.email);
        checkRequestData.append('otp', getProp.otp);
        checkRequestData.append('password', resetInputValues.npassword);
        const params = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        };
        axios.post(`${appConstant.API_URL}/user/forget_otp`, checkRequestData, params)
            .then(res => {
                setIsLoading(false);
                if (res?.data?.status === 200) {
                    alert(res?.data?.message);
                    window.location.href = appConstant.SITE_URL;
                }else {
                    setResultMessage(res?.data?.message);
                }
            })
            .catch(error => {
                setIsVerified(false);
            });
    }
    // reset password
    const [resetInputValues, setResetInputValues] = useState({
        otp : "",
        npassword : "",
        cnpassword : ""
    });
    const handleresetChange = (e) => {
        const {name, value} = e.target;
        setResetInputValues({...resetInputValues, [name]: value});
    }
    const [resetValidation, setResetValidation] = useState({
        otp : "",
        npassword : "",
        cnpassword : ""
    });
    const checkresetValidation = () => {
        let errors = JSON.parse(JSON.stringify(resetValidation));

        //password validation
        const npassword = resetInputValues.npassword;
        // const passReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6})/;
        if (!npassword) {
          errors.npassword = "New Password is required";
        }
        // else if (!npassword.match(passReg)) {
        //   errors.npassword = "Password must be at least 6 characters and must include at least one upper case letter, one lower case letter, one special character and one numeric digit.";
        // }
        else {
          errors.npassword = "";
        }
        //confirm password validation
        const cnpassword = resetInputValues.cnpassword;
        if (!cnpassword) {
        errors.cnpassword = 'Repeat New Password';
        } else if (cnpassword !== npassword) {
        errors.cnpassword = 'Both password should match';
        } else {
        errors.cnpassword = '';
        }
        setResetValidation(errors);
        if(errors.npassword === '' && errors.cnpassword === ''){
          return true;
        }else{
          return false;
        }
    };
    const resetPassword = (e) => {
        e.preventDefault();
        const isSuccess = checkresetValidation();
        if(isSuccess) {
            setIsLoading(true);
            ResetPassword();
            setLogin(true);
            setSignup(false);
            setForgot(false);
            setReset(false);
        }
    }
    const params = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
    const goToHome = () => {
        window.location.href = 'http://localhost:3000/';
    }
    const marquee = () => {
        axios.get(`${appConstant.API_URL}/c/highlights`, params)
            .then(res => {
            //   setIsLoading(false);
              if(res?.data?.status === 200){
                setMarquees(res?.data?.data);
              }else{
                  setMarquees([]);
              }
            })
            .catch(error => {
            //   setIsLoading(true);
            });
    }
    const handleClick = () => {
        inputRef.current.focus();
        inputRef1.current.focus();
    };
    return (
        <>
        <ScrollToTop/>
        <header id="header" className={'header fixed-top ' + (headerActiveClass ? 'active ' : '')}>
        
        <div className='marquee-box' >
            <h3>
            {marquees?.length > 0  && marquees?.map((result, i) => 
                <span key={'marquee' + i}>{result?.title}</span>
            )}
            </h3>
        </div>
            <nav className="navbar navbar-expand-xl navbar-light">
                <a className="navbar-brand" href='/'>
                    <img src={`${appConstant.CDN_URL}/assets/images/logo-white.png`} alt="Sage Controller"/> 
                </a>
                <div className='d-flex'>
                    <ul className="nav d-flex d-xl-none align-items-center">
                        <li className="nav-item">
                            <span className="nav-link" data-bs-toggle="collapse" data-bs-target="#search" onClick={handleClick}><i className="fa-solid fa-magnifying-glass"></i></span>
                        </li>
                        <div id="search" className="collapse search-form">
                            <form action="/shop" className='w-100 d-flex'>
                                <div className="searchBox mb-0">
                                    <input className="search-input form-control" ref={inputRef} type='search' name='s' placeholder="Search..."/>
                                    <button type="submit" className="search-btn">Go</button>
                                </div>
                            </form>
                        </div>
                        {!myAccount ? <>
                            <li className="nav-item">
                                <button type="button" className="nav-link bg-transparent" data-bs-toggle="modal" data-bs-target="#login"> <i className="fa-solid fa-user"></i></button>
                            </li> 
                            </>
                                : ''
                        }
                        {myAccount ? <>
                            <li className="nav-item">
                                <span className='nav-link myaccount' data-bs-toggle="dropdown">
                                    {localStorage.getItem('full_name') ? getInitials(localStorage.getItem('full_name')) : 'Guest'}                               
                                </span>

                                <ul className="dropdown-menu" style={{width:'150px',left : 'auto', right: '0'}}>
                                    <li><a className="dropdown-item" href={"/my-profile"}>My Profile</a></li>
                                    <li><a className="dropdown-item" href={"/my-orders/products"}>Product Orders</a></li>
                                    <li><a className="dropdown-item" href={"/my-orders/services"}>Service Orders</a></li>
                                    <li><a className="dropdown-item" href={"/shipping-address"}>Shipping Address</a></li>
                                    <li><a className="dropdown-item" href={"/logout"}>Logout</a></li>
                                </ul>
                            </li>
                            </>
                            : ''
                        }
                        <li className="nav-item">
                            <Link className="nav-link position-relative" to='/cart'><i className="fa-solid fa-cart-shopping"></i><span className="count">{cartTotal()}</span></Link>
                        </li>
                    </ul>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </div>
                <div className="collapse navbar-collapse" id="collapsibleNavbar">
                    <ul className="navbar-nav ms-auto mb-2 mb-xl-0"> 
                        <li className={"nav-item menu-hover dropdown " + (props.id === "1" ? 'active' : '')}>
                            <div className="nav-link dropdown-toggle">PS5 CONTROLLERS</div>
                            <div className="dropdown-menu show">
                                <div className="d-flex p-3 justify-content-between align-items-center">
                                    <div className="flex-grow-1 flex-menu">
                                        {/* <Link to='/category/ps4' className="flex-submenu">
                                            <div className="d-flex align-items-center">
                                                <img className="menu-image" src={`${appConstant.CDN_URL}/assets/images/ps5-menu-1.png`} alt="ps5_controllers_waranty."/>
                                                <div>
                                                    <h4 className="flex-submenu-title">BUILD YOUR OWN CUSTOM PS5 CONTROLLER</h4>
                                                    <h6 className="flex-submenu-desc" >Create Custom PS5 Controller for PS5</h6>
                                                </div>
                                            </div>
                                        </Link> */}
                                        <a href='/category/ps5_custom_controllers' className="flex-submenu">
                                            <div className="d-flex align-items-center">
                                                <img className="menu-image" src={`${appConstant.CDN_URL}/assets/images/ps5-menu-2.png`} alt="ps5_controllers_waranty."/>
                                                <div>
                                                    <h4 className="flex-submenu-title">PS5 CUSTOM CONTROLLERS</h4>
                                                    <h6 className="flex-submenu-desc" >Crafted for Your Gaming Journey.</h6>
                                                </div>
                                            </div>
                                        </a>                                    
                                        <a href='/category/ps5_limited_edition' className="flex-submenu">
                                            <div className="d-flex align-items-center">
                                                <img className="menu-image" src={`${appConstant.CDN_URL}/assets/images/ps5-menu-3.png`} alt="ps5_controllers_waranty."/>
                                                <div>
                                                    <h4 className="flex-submenu-title">PS5 LIMITED EDITION</h4>
                                                    <h6 className="flex-submenu-desc" >Exclusive. Limited. Never Returning.</h6>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <img className="menu-banner" src={`${appConstant.CDN_URL}/assets/images/ps5_controllers_waranty.png`} alt="ps5_controllers_waranty."/> 
                                </div>
                            </div>
                        </li>
                        <li className={"nav-item menu-hover dropdown " + (props.id === "2" ? 'active' : '')}>
                            <div className="nav-link dropdown-toggle">XBOX CONTROLLERS</div>
                            <div className="dropdown-menu show">
                                <div className="d-flex p-3 justify-content-between align-items-center">
                                    <div className="flex-grow-1 flex-menu">                                        
                                        <a href='/category/xbox_custom_controllers' className="flex-submenu">
                                            <div className="d-flex align-items-center">
                                                <img className="menu-image" alt='' src={`${appConstant.CDN_URL}/assets/images/xbox-menu-2.png" alt="xbox_controllers_waranty.`}/>
                                                <div>
                                                    <h4 className="flex-submenu-title">XBOX CUSTOM CONTROLLERS</h4>
                                                    <h6 className="flex-submenu-desc" >Choose. Customize. Unleash Your Style</h6>
                                                </div>
                                            </div>
                                        </a>  
                                        <a href='/category/xbox_limited_edition' className="flex-submenu">
                                            <div className="d-flex align-items-center">
                                                <img className="menu-image" src={`${appConstant.CDN_URL}/assets/images/xbox-menu-1.png`} alt="xbox_controllers_waranty."/>
                                                <div>
                                                    <h4 className="flex-submenu-title">XBOX LIMITED EDITION</h4>
                                                    <h6 className="flex-submenu-desc" >Once Gone, Forever Collectible</h6>
                                                </div>
                                            </div>
                                        </a>    
                                    </div>
                                    <img className="menu-banner" src={`${appConstant.CDN_URL}/assets/images/banner-menu-xboxv2.jpg`} alt="xbox_controllers_waranty."/> 
                                </div>
                            </div>
                        </li>
                        <li className={"nav-item menu-hover dropdown " + (props.id === "3" ? 'active' : '')}>
                            <div className="nav-link dropdown-toggle">PS4 CONTROLLERS</div>
                            <div className="dropdown-menu show">
                                <div className="d-flex p-3 justify-content-between align-items-center">
                                    <div className="flex-grow-1 flex-menu">
                                        <a href='/category/ps4_custom_controllers' className="flex-submenu">
                                            <div className="d-flex align-items-center">
                                                <img className="menu-image" src={`${appConstant.CDN_URL}/assets/images/ps4-menu-2.png`} alt="ps4_controllers_waranty."/>
                                                <div>
                                                    <h4 className="flex-submenu-title">PS4 CUSTOM CONTROLLERS</h4>
                                                    <h6 className="flex-submenu-desc" >Your Style, Your Custom Controller.</h6>
                                                </div>
                                            </div>
                                        </a> 
                                        <a href='/category/ps4_limited_edition' className="flex-submenu">
                                            <div className="d-flex align-items-center">
                                                <img className="menu-image" src={`${appConstant.CDN_URL}/assets/images/ps4-menu-1.png`} alt="ps4_controllers_waranty."/>
                                                <div>
                                                    <h4 className="flex-submenu-title">PS4 LIMITED EDITION</h4>
                                                    <h6 className="flex-submenu-desc" >Lasting Rarity, Never Replenished</h6>
                                                </div>
                                            </div>
                                        </a>
                                            
                                    </div>
                                    <img className="menu-banner" src={`${appConstant.CDN_URL}/assets/images/banner-menu-ps4-1.jpg`} alt="ps4_controllers_waranty."/> 
                                </div>
                            </div>
                        </li>                                
                        <li className={"nav-item menu-hover dropdown " + (props.id === "4" ? 'active' : '')}>
                            <div className="nav-link dropdown-toggle ">SHOP</div>
                            <div className="dropdown-menu show"> 
                                <div className="d-md-flex flex-menu align-items-center justify-content-between p-3">
                                    <div className='shop-submenu'>
                                        <a href='/category/playstation_accessories' className="flex-submenu">
                                            <div className="d-flex align-items-center">
                                                <img className="menu-image" src={`${appConstant.CDN_URL}/assets/images/mousepad_120.png`} alt="shop_controllers_waranty."/>
                                                <div>
                                                    <h4 className="flex-submenu-title">PLAYSTATION ACCESSORIES</h4>
                                                </div>
                                            </div>
                                        </a>
                                        <a href='/category/kontrol_freeks' className="flex-submenu">
                                            <div className="d-flex align-items-center">
                                                <img className="menu-image" src={`${appConstant.CDN_URL}/assets/images/accessories_thumbnail.jpg`} alt="shop_controllers_waranty."/>
                                                <div>
                                                    <h4 className="flex-submenu-title">KONTROL FREEKS</h4>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div className='shop-submenu'>
                                        <a href='/shop' className="flex-submenu">
                                            <div className="d-flex align-items-center justify-content-md-center flex-md-column">
                                                <img className="menu-image me-md-0" src={`${appConstant.CDN_URL}/assets/images/mouses_120.png`} alt="shop_controllers_waranty."/>
                                                <div>
                                                    <h4 className="flex-submenu-title">ALL PRODUCTS</h4>
                                                </div>
                                            </div>
                                        </a>  
                                    </div>
                                    <div className='shop-submenu'>
                                        <a href='/category/xbox_accessories' className="flex-submenu">
                                            <div className="d-flex align-items-center">
                                                <img className="menu-image" src={`${appConstant.CDN_URL}/assets/images/gadgets-thumbnail.png`} alt="shop_controllers_waranty."/>
                                                <div>
                                                    <h4 className="flex-submenu-title">XBOX ACCESSORIES</h4>
                                                </div>
                                            </div>
                                        </a>
                                        <a href='/category/exclusive_accessories' className="flex-submenu">
                                            <div className="d-flex align-items-center">
                                                <img className="menu-image" src={`${appConstant.CDN_URL}/assets/images/exclusive.png`} alt="shop_controllers_waranty."/>
                                                <div>
                                                    <h4 className="flex-submenu-title">EXCLUSIVE ACCESSORIES</h4>
                                                </div>
                                            </div>
                                        </a> 
                                    </div>                               
                                </div>                                                           
                            </div>
                        </li> 
                        <li className={"nav-item menu-hover dropdown " + (props.id === "5" ? 'active' : '')}>
                            <div className="nav-link dropdown-toggle">SERVICES</div>
                            <div className="dropdown-menu show">
                                <div className="d-flex p-3 justify-content-between align-items-center">
                                    <div className="flex-grow-1 flex-menu">
                                        <a href='/services/send-in-your-controller' className="flex-submenu">
                                            <div className="d-flex align-items-center">
                                                <img className="menu-image" src={`${appConstant.CDN_URL}/assets/images/services.png`} alt="services_controllers_waranty."/>
                                                <div>
                                                    <h4 className="flex-submenu-title">SEND IN YOUR CONTROLLER</h4>
                                                    <h6 className="flex-submenu-desc" >Send Personal Controller for Customization</h6>
                                                </div>
                                            </div>
                                        </a>
                                        <a href='/services/repair-and-customize' className="flex-submenu">
                                            <div className="d-flex align-items-center">
                                                <img className="menu-image" src={`${appConstant.CDN_URL}/assets/images/repair.png`} alt="services_controllers_waranty."/>
                                                <div>
                                                    <h4 className="flex-submenu-title">REPAIR & CUSTOMIZE</h4>
                                                    <h6 className="flex-submenu-desc" >Send Controller for Repair & Customize</h6>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <img className="menu-banner" src={`${appConstant.CDN_URL}/assets/images/settings.png`} alt="services_controllers_waranty."/> 
                                </div>
                            </div>
                        </li>
                        <li className={"nav-item menu-hover dropdown d-flex d-xl-none"}>
                            <div className="nav-link w-100 text-center">
                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
                                    <img className='close-menu' src={`${appConstant.CDN_URL}/assets/images/close.png`} alt='close' />
                                </button>                            
                            </div>                            
                        </li>   
                        <li className="nav-item d-none d-xl-flex">
                            <span className="nav-link" data-bs-toggle="collapse" onClick={handleClick} data-bs-target="#search"><i className="fa-solid fa-magnifying-glass"></i></span>
                        </li> 
                        <div id="search" className="collapse search-form">
                            <form action="/shop" className='w-100 d-flex'>
                                <div className="searchBox mb-0">
                                    <input className="search-input form-control" ref={inputRef1} type='search' name='s' placeholder="Search..."/>
                                    <button type="submit" className="search-btn">Go</button>
                                </div>
                            </form>
                        </div>

                        {!myAccount ? <>
                            <li className="nav-item d-none d-xl-flex">
                                <button type="button" className="nav-link" data-bs-toggle="modal" data-bs-target="#login"> <i className="fa-solid fa-user"></i></button>
                            </li> 
                            </>
                                : ''
                        }
                        {myAccount ? <>
                            <li className="nav-item d-none d-xl-flex">
                                <span className='nav-link myaccount' data-bs-toggle="dropdown">
                                    {localStorage.getItem('full_name') ? getInitials(localStorage.getItem('full_name')) : 'Guest'}                               
                                </span>

                                <ul className="dropdown-menu" style={{width:'150px',left : 'auto', right: '0'}}>
                                    <li><a className="dropdown-item" href={"/my-profile"}>My Profile</a></li>
                                    <li><a className="dropdown-item" href={"/my-orders/products"}>Product Orders</a></li>
                                    <li><a className="dropdown-item" href={"/my-orders/services"}>Service Orders</a></li>
                                    <li><a className="dropdown-item" href={"/shipping-address"}>Shipping Address</a></li>
                                    <li><a className="dropdown-item" href={"/logout"}>Logout</a></li>
                                </ul>
                            </li>
                            </>
                            : ''
                        }
                        <li className="nav-item d-none d-xl-flex">
                            <Link className="nav-link" to='/cart'><i className="fa-solid fa-cart-shopping"></i><span className="count cart-zoom">{cartTotal()}</span></Link>
                        </li>                      
                    </ul>
                </div>
            </nav>
        </header>
       
        {/* login popup  */}
        <div className="modal back-shadow" id="login" style={{display : showResetPasswordBox ? 'block' : 'none' }}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    {!showResetPasswordBox && <button type="button" className="close" data-bs-dismiss="modal">&times;</button>}
                    <div className="modal-body">
                    {login ? <>
                        <h4 className='title'>Login</h4>
                            {resultMessage && <p className='text-center mx-3 formErrors mb-2'>{resultMessage}</p>}
                        <form className='login-form' onSubmit={(e) => {logIn(e)}}>
                            <label>Email:</label>
                            <input type="text" placeholder="Enter Email" name='email' value={loginInputValues.email} onChange={(e) => handleLoginChange(e)}/>
                            {loginValidation.email && <p className='formErrors'>{loginValidation.email}</p>}
                            <label>Password:</label>
                            <div className='position-relative'>
                            <input type={passwordShown ? "text" : "password"} placeholder="Enter Password" name='password' value={loginInputValues.password} onChange={(e) => handleLoginChange(e)}/>
                            <img src={passwordShown ? `${appConstant.CDN_URL}/assets/images/eyeshow.png` : `${appConstant.CDN_URL}/assets/images/eyehide.png`} className="password-hide" alt="password" onClick={togglePassword}/>
                            </div>
                            {loginValidation.password && <p className='formErrors'>{loginValidation.password}</p>}
                            <label className="d-flex align-items-center justify-content-between my-4">
                                <div className="form-check form-switch mb-0">
                                    <input className="form-check-input mb-0" type="checkbox" name="hide-billing" checked={checked} onChange={e => setChecked(e.target.checked)}/>
                                    <label className='ms-3 mb-0'>Remember me</label>
                                </div>
                                <span className='forget-link links' onClick={showForgot}>Forgot Password</span>
                            </label>
                            {isLoading ? <div className='text-center mb-4'><button type='submit' className="btn-login login-2">Login <img className='ms-2' src={`${appConstant.CDN_URL}/assets/images/default.gif`} width={'16px'} height={'16px'} alt={'default'}/></button></div> :
                               <div className='text-center mb-4'><button type='submit' className="btn-login login-2">Login</button></div>
                            }

                            <h6 className='tagline'>Don't have account? <button className='links' onClick={showSignup}>Signup</button></h6>
                        </form>
                        </>
                        : ''
                    }
                    {signup ? <>
                        <h4 className='title'>Signup</h4>
                            {resultMessage && <p className='text-center mx-3 formErrors mb-2'>{resultMessage}</p>}
                        <form className='signup-form' onSubmit={signUp}>
                            <label>First Name:</label>
                            <input type="text" placeholder="First Name" name="fname" value={signupInputValues.fname} onChange={(e) => handleSignupChange(e)}/>
                            {signupValidation.fname && <p className='formErrors'>{signupValidation.fname}</p>}
                            <label>Last Name:</label>
                            <input type="text" placeholder="Last Name" name="lname" value={signupInputValues.lname} onChange={(e) => handleSignupChange(e)}/>
                            {signupValidation.lname && <p className='formErrors'>{signupValidation.lname}</p>}
                            <label>Mobile Number:</label>
                            <input type="text" placeholder="Mobile Number" name="number" value={signupInputValues.number} onChange={(e) => handleSignupChange(e)}/>
                            {signupValidation.number && <p className='formErrors'>{signupValidation.number}</p>}
                            <label>Email:</label>
                            <input type="text" placeholder="Email" name="email" value={signupInputValues.email} onChange={(e) => handleSignupChange(e)}/>
                            {signupValidation.email && <p className='formErrors'>{signupValidation.email}</p>}
                            <label>Password:</label>
                            <div className='position-relative'>
                            <input type={passwordShown ? "text" : "password"} placeholder="Password" name="password" value={signupInputValues.password} onChange={(e) => handleSignupChange(e)}/>
                            <img src={passwordShown ? `${appConstant.CDN_URL}/assets/images/eyeshow.png` : `${appConstant.CDN_URL}/assets/images/eyehide.png`} className="password-hide" alt="password" onClick={togglePassword}/>
                            </div>
                            {signupValidation.password && <p className='formErrors'>{signupValidation.password}</p>}
                            <label>Confirm Password:</label>
                            <div className='position-relative'>
                            <input type={confirmPasswordShown ? "text" : "password"} placeholder="Confirm Password" name="cpassword" value={signupInputValues.cpassword} onChange={(e) => handleSignupChange(e)}/>
                            <img src={confirmPasswordShown ? `${appConstant.CDN_URL}/assets/images/eyeshow.png` : `${appConstant.CDN_URL}/assets/images/eyehide.png`} className="password-hide" alt="password" onClick={toggleConfirmPassword}/>
                            </div>
                            {signupValidation.cpassword && <p className='formErrors'>{signupValidation.cpassword}</p>}
                            {/* <label>Address:</label> */}
                            {/* <input type='text' name='address' placeholder='Address' value={signupInputValues.address} onChange={(e) => handleSignupChange(e)}/>
                            {signupValidation.address && <p className='formErrors'>{signupValidation.address}</p>} */}
                            {/* <label>City</label>
                            <input type="text" placeholder='Enter City' name='city' value={signupInputValues.city} onChange={(e) => handleSignupChange(e)}/>
                            {signupValidation.city && <p className='formErrors'>{signupValidation.city}</p>} */}
                            {/* <label>State:</label> */}
                            {/* <select name="state" value={signupInputValues.state} onChange={(e) => handleSignupChange(e)}>
                                <option value="">Select State</option> */}
                              {/* {appConstant.STATES.length > 0  && appConstant.STATES?.map((result, i) =>
                                  <option value={result} key={i}>{result}</option>
                              )} */}
                            {/* </select> */}
                            {/* {signupValidation.state && <p className='formErrors'>{signupValidation.state}</p>} */}
                            {/* <label>Country</label>
                            <select name="country"  value={signupInputValues.country} onChange={(e) => handleSignupChange(e)}>
                                <option value="">Select Country</option>
                                <option value="USA">USA</option>
                            </select>
                            {signupValidation.country && <p className='formErrors'>{signupValidation.country}</p>}
                            <label>Zip/Postal Code</label>
                            <input type="text" placeholder='Enter Zip/Postal Code' name='zip'  value={signupInputValues.zip} onChange={(e) => handleSignupChange(e)}/> */}
                            {/* {signupValidation.zip && <p className='formErrors'>{signupValidation.zip}</p>} */}
                            {isLoading ? <div className='text-center mb-4'><button type='submit' className="btn-login login-2">Signup <img className='ms-2' src={`${appConstant.CDN_URL}/assets/images/default.gif`} width={'16px'} height={'16px'} alt={'signup'}/></button></div> :
                               <div className='text-center mb-4'><button type='submit' className="btn-login login-2">Signup</button></div>
                            }
                            <h6 className='tagline'>Have account? <button className='links' onClick={showLogin}>Login</button></h6>
                        </form>
                        </>
                        : ''
                    }
                    {forgot ? <>
                        <h4 className='title'>Forgot Password</h4>
                        {resultMessage && <p className='text-center mx-3 formErrors mb-2'>{resultMessage}</p>}
                        <form className='forgot-form' onSubmit={forgotPassword}>
                            <label>Email:</label>
                            <input type="text" placeholder="Enter Email" name="email" value={forgotInputValues.email} onChange={(e) => handleForgotChange(e)}/>
                            {forgotValidation.email && <p className='formErrors'>{forgotValidation.email}</p>}
                            {isLoading ? <div className='text-center mb-4'><button type='submit' className="btn-login login-2">Submit <img className='ms-2' alt='loader' src={`${appConstant.CDN_URL}/assets/images/default.gif`} width={'16px'} height={'16px'}/></button></div> :
                               <div className='text-center mb-4'><button type='submit' className="btn-login login-2">Submit</button></div>
                            }
                            <h6 className='tagline mb-2'>OR</h6>
                            <h6 className='tagline'><button className='links' onClick={showLogin}>Login</button> <button className='links' onClick={showSignup}>Signup</button></h6>
                        </form>
                        </>
                        : ''
                    }
                    {reset ? <>

                    {isVerified ? <> <h4 className='title'>Reset Password</h4>
                        {resultMessage && <p className='text-center mx-3 formErrors mb-2'>{resultMessage}</p>}
                        <form className='reset-form' onSubmit={resetPassword}>
                            <label>New Password:</label>
                            <div className='position-relative'>
                            <input type={passwordShown ? "text" : "password"} placeholder="Password" name="npassword" value={resetInputValues.npassword} onChange={(e) => handleresetChange(e)}/>
                            <img src={passwordShown ? `${appConstant.CDN_URL}/assets/images/eyeshow.png` : `${appConstant.CDN_URL}/assets/images/eyehide.png`} className="password-hide" alt="password" onClick={togglePassword}/>
                            </div>
                            {resetValidation.npassword && <p className='formErrors'>{resetValidation.npassword}</p>}
                            <label>Confirm New Password:</label>
                            <div className='position-relative'>
                            <input type={confirmPasswordShown ? "text" : "password"} placeholder="Confirm Password" name="cnpassword" value={resetInputValues.cnpassword} onChange={(e) => handleresetChange(e)}/>
                            <img src={confirmPasswordShown ? `${appConstant.CDN_URL}/assets/images/eyeshow.png` : `${appConstant.CDN_URL}/assets/images/eyehide.png`} className="password-hide" alt="password" onClick={toggleConfirmPassword}/>
                            </div>
                            {resetValidation.cnpassword && <p className='formErrors'>{resetValidation.cnpassword}</p>}
                            {isLoading ? <div className='text-center mb-4'><button type='submit' className="btn-login login-2">Change Password <img className='ms-2' src={`${appConstant.CDN_URL}/assets/images/default.gif`} alt='loader' width={'16px'} height={'16px'}/></button></div> :
                               <div className='text-center mb-4'><button type='submit' className="btn-login login-2">Change Password</button></div>
                            }
                            <h6 className='tagline mb-2'>OR</h6>
                            <h6 className='tagline'><button className='links' onClick={showLogin}>Login</button> <button className='links' onClick={showSignup}>Signup</button></h6>
                        </form>
                        </> 
                        : 
                        <> <h4 className='mb-4 text-center' style={{color: 'red'}}>{checkRequestMessage}</h4>
                                <button className='btn-login login-2 d-block m-auto' data-bs-dismiss="modal" onClick={() => {goToHome()}}>Go to Home</button> </>}
                        </>
                        : ''
                    }
                    </div>
                </div>
            </div>
        </div>
        </>
    );
  }
  
  export default Header;
  